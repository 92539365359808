import React from 'react'

import { Column, Container, Row } from '@/components/base/gridview'
import Image from '@/components/base/image/Image'
import Anchor from '@/components/base/anchor/Anchor'

import { ChargeStationProps } from './type'

const ChargeStation: React.FunctionComponent<ChargeStationProps> = ({
  title,
  subTitle,
  image,
  children,
}) => {
  return (
    <div className="charge-station section text-center">
      <Container size="medium">
        <Row xs={{ align: 'center' }}>
          <Column
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 12 }}
            xl={{ size: 12 }}
            className="text-center"
          >
            <div className="section-title">
              {title} <span>{subTitle}</span>
            </div>
          </Column>
        </Row>
        <Row>
          <Column
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 12 }}
            xl={{ size: 12 }}
          >
            <figure>
              <Image {...image} alt="" />
            </figure>
          </Column>
        </Row>
        <Row className="children">{children}</Row>
      </Container>
    </div>
  )
}

export default ChargeStation
